(() => {

    const WebFont = require('webfontloader');

    WebFont.load({
        google: {
            families: ['Anton:400:latin', 'Montserrat:400,500,600:latin-ext']
        }
    });
})();

