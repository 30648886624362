import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export function about() {
    
    let pool = false;
    
    function toggle(option) {      
        if (option === false) {
             console.log('disabled') 
        } else {    
            //ScrollTrigger.getById('parallax').enable(false) 
    
            if (pool === false) {
                gsap.utils.toArray(".a-fic > *").forEach(function(section) {
                    gsap.from(section, {
                        scrollTrigger: {
                            trigger: section,
                            start: '-40px bottom',
                            //toggleActions: "play complete complete reset",
                        },
                        opacity: 0, 
                        duration: 1,
                        y: 40
                    });
                });
                
                ScrollTrigger.batch(".c-team__item", {
                    onEnter: elements => {
                        gsap.from(elements, {
                            autoAlpha: 0,
                            y: -40,
                            duration: 2,
                            scale: 0.1,
                            ease: 'elastic.out(1, .8)',
                            stagger: {
                                from: "left",
                                amount: .9
                            },            
                        });
                    },
                    
                    once: false
                });
                
                pool = true;
            } 
        }     
    }

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".c-about",
            pin: true,
            start: "top top",
            end: "+=2000", 
            scrub: 2,
            onEnter: () => toggle(false),
            onLeave: () => toggle(true),
            onEnterBack: () => toggle(false),
            onLeaveBack: () => toggle(true),
            invalidateOnRefresh: true
        }
    });
    
    tl.addLabel('step0')
    tl.fromTo('.o-circle--01', 
        {
            autoAlpha: 0,
            x: -500
        },
        {
            autoAlpha: 1,
            x: 100,
            ease: 'Power1.easeInOut',
            duration: 1
        }, 0
    )
    
    tl.fromTo('.o-circle--02', 
        {
            autoAlpha: 0,
            x: 500,
        },
        {  
            autoAlpha: 1,
            x: -100,
            ease: 'Power1.easeInOut',
            duration: 1
        }, 0
    )
    
    
    tl.addLabel("step1")
    tl.to('.o-circle--01', 
        {
            backgroundColor: '#BE73AF',
            x: 0,
            yPercent: 100,
            ease: 'Power1.easeInOut',
        }, 1
    )
    
    tl.to('.o-circle--02', 
        {  
            backgroundColor: '#BE73AF',
            x: 0,
            yPercent: 100,
            ease: 'Power1.easeInOut',
        }, 1
    )
    
    tl.to('.c-about h2', 
        {  
            yPercent: -200,
            autoAlpha: 0,
            display: 'none',
            ease: 'Power1.easeInOut',
            duration: 1,
        }, 1
    )
    
    
    tl.addLabel("step2")
    tl.to('.o-circle--01', 
        {
            scale: 6,
            duration: 1,
            ease: 'Power1.easeInOut',
        }
    )   

    tl.fromTo('.c-about ul', 
        {
            autoAlpha: 0,
            display: 'none',
            opacity: 0,
            yPercent: 100,
        },
        {  
            autoAlpha: 1,
            display: 'block',
            opacity: 1,
            yPercent: 0
        }, '-=.5'
    )
    
    tl.to({}, { duration: 1 }) 
    
    gsap.from(".c-about h2", {
        scale: .8,
        scrollTrigger: {
            trigger: ".c-about",
            scrub: 1,
            start: 'top center',
        }, 
    });
}

// add animations and labels to the timeline
/*
tl.addLabel("start")
.from(".box p", {scale: 0.3, rotation:45, autoAlpha: 0})
.addLabel("color")
.from(".box", {backgroundColor: "#28a92b"})
.addLabel("spin")
.to(".box", {rotation: 360})
.addLabel("end");
}
*/
