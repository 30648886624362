document.addEventListener('DOMContentLoaded', () => {
    var el = document.getElementById("video"); 
    
    const pause_it = function() {
        document.querySelector('.c-video').classList.add('is-paused');
        el.pause(); 
    }
    
    const play_it = function() {
        el.play(); 
        window.runScroll('#what');
        document.querySelector('.c-video').classList.remove('is-paused');
    }

    const playPause = ()=> { 
        el.paused ? play_it() : pause_it();
    }

    const observer = new IntersectionObserver(function (entries) {
        !entries[0].isIntersecting ? pause_it() : false;
    });
    
    observer.observe(document.querySelector("#what"))
    el.addEventListener('click', playPause);
    
    
    
/*
    import Plyr from 'plyr';
    require('../../node_modules/plyr/dist/plyr.css');
        
        const player = new Plyr('#player', {
            vimeo: { 
                sidedock: 0, 
                controls: 0,
            },
            controls: [],
            ratio: 'null'
        });
*/

}, false)
