import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { about } from "../js/anims/about.js";
import { hero } from "../js/anims/hero.js";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    
    window.anims = () => {
        document.querySelector('.c-hero') ? hero() : false;
        document.querySelector('.c-about') ? about() : false;
    };

}, false)
