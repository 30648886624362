const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded', () => {

    const nav = document.querySelector('.js-nav'),
          hamburger = document.querySelector('.js-hamburger'),
          menu = document.querySelector('.js-menu');

    const init = ()=> {
        window.hideMenu = ()=> {
            if ( document.querySelector('.js-nav').classList.contains('is-visible')) {
                document.querySelector('.js-nav').classList.remove('is-visible');
                document.querySelector('.js-hamburger').classList.remove('is-active');
                
                document.removeEventListener('click', clickOutside);
                document.documentElement.classList.remove('mobile-menu');
                
                setTimeout(function() {
                    document.querySelector('.js-nav').classList.remove('is-block');
                    document.querySelector('.js-nav').classList.remove('is-animated');
                }, 400);
                
                enableBodyScroll(document.querySelector('.js-menu'));
            }            
        };

        const showMenu = function() {
            
            nav.classList.add('is-block');
            hamburger.classList.add('is-active');
            document.documentElement.classList.add('mobile-menu');

            setTimeout(function() {
                nav.classList.add('is-visible');
                document.addEventListener('click', clickOutside);
            }, 100);    

            if (window.innerWidth <= 1024) {
                disableBodyScroll(menu);
            }
        };

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };
        
        const clickOutside = function(e) {
            if (!e.target.closest('.js-nav')) {
                hideMenu();
        	}
        };

        hamburger.addEventListener('click', toggleMenu);

        // Hide menu on ESC

        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });
        
        
        const checkWindowSize = ()=> {
            
            if (window.innerWidth > 640) {
                hideMenu();
                window.removeEventListener('resize', checkWindowSize);
            }
        };
        
        
        window.addEventListener('resize', checkWindowSize);

    };

    nav ? init() : false;

}, false);