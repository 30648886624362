import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export function hero() {
    
    const firstLine = document.querySelector('.c-hero p:nth-of-type(1)'),
          lastLine = document.querySelector('.c-hero p:nth-of-type(2)');
          
    lastLine.innerHTML = lastLine.textContent.replace(/\S/g, "<span class='char'>$&</span>");
    lastLine.style.visibility = 'visible';
    
    let chars = lastLine.querySelectorAll('.char');

    let tl = gsap.timeline();

    tl.fromTo(firstLine,
        {
            autoAlpha: 0,
            y: -20,
            scaleX: -1,
            opacity: 0,
        },
        {
            autoAlpha: 1,
            y: 0,
            opacity: 1,
            stagger: 0.05,
            duration: .8,
            scaleX: 1,
            ease: 'elastic.out(1, .8)',
        }
    )

    tl.from(document.querySelectorAll('.first'), {
        autoAlpha: 0,
        opacity: 0,
        y: -60,
        duration: 1.1,
        ease: 'elastic.out(1, .8)',
    }, '-=.5').from(document.querySelectorAll('.second'), {
        autoAlpha: 0,
        opacity: 0,
        y: 60,
        duration: 1.1,
        ease: 'elastic.out(1, .8)',
    }, '-=.9').from(document.querySelectorAll('.third'), {
        autoAlpha: 0,
        opacity: 0,
        y: -60,
        duration: 1.1,
        ease: 'elastic.out(1, .8)',
    }, '-=.9')


    tl.fromTo(chars,
        {
            opacity: 0,
        },
        {
            opacity: 1,
            stagger: 0.02,
            duration: 1,
        },  '-=1'
    )
    
    tl.fromTo('.c-hero .o-sign',
        {
            autoAlpha: 0,
            opacity: 0,
            xPercent: 10
        },
        {
            autoAlpha: 1,
            opacity: 1,
            duration: 1,
            xPercent: 0,
            ease: 'elastic.out(1, .8)',
        },  '-=1.5'
    )
    
    tl.fromTo('.c-topbar',
        {
            autoAlpha: 0,
            opacity: 0,
            yPercent: -100
        },
        {
            autoAlpha: 1,
            opacity: 1,
            duration: 1,
            yPercent: 0,
            ease: 'elastic.out(1, .8)',
        },  '-=1.5'
    )
    
    gsap.to(".c-hero", {
        yPercent: 40,
        ease: "none",
        scrollTrigger: {
            trigger: ".c-hero",
            scrub: 0,
            start: 'top top',
        }, 
    });
    
    tl.fromTo('.c-vinyl',
        {
            autoAlpha: 0,
            opacity: 0,
            scale: .1
            //Percent: -100
        },
        {
            autoAlpha: 1,
            opacity: 1,
            duration: 1,
            scale: 1,
//            yPercent: 0,
            ease: 'elastic.out(1, .8)',
        },  '-=1.5'
    )
}
