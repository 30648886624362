import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', ()=> {
    
    window.runScroll = function(el, o) {
        let offsetTop = document.querySelector(el).offsetTop,
            topbar = document.querySelector('.c-topbar');

        o === undefined ? o = 0 : false;

        document.documentElement.classList.contains('mobile-menu') ? window.hideMenu() : false;

        scroll({
            top: offsetTop - o,
            behavior: "smooth"
        })
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;

            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;

            let url = e.currentTarget.href;
            
            document.querySelector(target) ? window.runScroll(target, offset) :
                window.open(url + target, '_self');
        };

        for (let i = 0; i < gtt.length; i++) {
            console.log('f')
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
